<template>
  <DetailTemplate
    :customClass="'customer-detail detail-page'"
    v-if="getPermission('customer:view')"
  >
    <template v-slot:header-title>
      <v-skeleton-loader
        class="custom-skeleton"
        v-if="pageLoading"
        width="400"
        height="28"
        type="text"
      >
      </v-skeleton-loader>
      <div class="d-flex">
        <h1
          class="form-title custom-nowrap-ellipsis text-capitalize mr-2"
          v-if="customerArr.company_type == 'company'"
        >
          {{ getPageTitle }}
        </h1>
        <h1
          class="form-title custom-nowrap-ellipsis text-capitalize mr-2"
          v-else
        >
          <template
            v-if="
              customerArr &&
              customerArr.default_person &&
              customerArr.default_person.display_name
            "
            >{{ customerArr.default_person.display_name }}</template
          >
        </h1>
        <v-chip
          label
          color="chip-custom-blue"
          outlined
          class="text-white p-3"
          style="font-size: 21px !important; font-weight: 700"
          >{{ customerArr.barcode }}</v-chip
        >
        <span>
          <v-chip
            color="blue white--text"
            label
            class="ml-2"
            v-if="customerArr.company_type == 'company'"
          >
            <span class="font-size-16 font-weight-500"> Company </span>
          </v-chip>
          <v-chip
            color="red white--text"
            label
            class="ml-2"
            v-if="customerArr.company_type == 'personal'"
          >
            <span class="font-size-16 font-weight-500"> Individual </span>
          </v-chip>
        </span>
      </div>
    </template>
    <template v-slot:header-action>
      <v-skeleton-loader
        class="custom-skeleton d-inline-block mr-2"
        v-if="pageLoading"
        type="button"
        height="28"
      >
      </v-skeleton-loader>
      <template v-else>
        <div class="d-flex">
          <v-switch
            v-on:click.prevent.stop="activeInactive(customerArr)"
            v-model="customerArr.activated"
            class="mt-0"
            inset
            color="green"
          ></v-switch>
          <v-chip
            v-if="customerArr.activated == 1"
            class="mx-2 custom-status font-weight-600 custom-grey-border d-inline-table text-uppercase justify-center font-8 text-white"
            color="#66bb6a"
            label
          >
            Active
          </v-chip>
          <v-chip
            v-if="customerArr.activated == 0"
            class="mx-2 custom-status font-weight-600 custom-grey-border d-inline-table text-uppercase justify-center font-10 text-white"
            color="red"
            label
          >
            In-Active
          </v-chip>
          <v-chip
            v-if="customerArr.transfer_to != 0"
            class="mx-2 custom-status font-weight-600 custom-grey-border d-inline-table text-uppercase justify-center font-10 text-white"
            color="red"
            label
          >
            Ownership Transfered
          </v-chip>
          <v-btn
            v-else
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            v-on:click="transferOwnership()"
          >
            Transfer of Ownership
            <v-icon right class="mr-1">mdi-account-switch-outline</v-icon>
          </v-btn>
          <v-menu
            v-if="createTransactions.length > 0"
            :close-on-click="true"
            bottom
            content-class="custom-menu-list"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2 custom-bold-button white--text"
                v-bind="attrs"
                v-on="on"
                color="cyan"
              >
                Create... <v-icon right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <template v-for="(item, index) in createTransactions">
                <v-list-item
                  :key="index"
                  link
                  v-on:click="createTransaction(customerArr, item.action)"
                >
                  <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                    <span class="svg-icon svg-icon-lg svg-v-list-icon">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        :src="
                          $assetURL('media/custom-svg/' + item.action + '.svg')
                        "
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </v-list-item-icon>
                  <v-list-item-title class="font-size-14 mt-2 mb-2">{{
                    item.title
                  }}</v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
          <template v-if="getPermission('customer:update') && false">
            <v-menu
              transition="slide-y-transition"
              bottom
              content-class="custom-menu-list"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mx-2 custom-bold-button white--text"
                  color="cyan"
                  v-bind="attrs"
                  v-on="on"
                >
                  More... <v-icon right>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <template v-for="(more, index) in customerMoreAction">
                  <v-list-item
                    link
                    v-on:click="updateMoreAction(more.action)"
                    :key="index"
                  >
                    <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                      <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="font-weight-500 font-size-14">{{
                      more.title
                    }}</v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </template>
          <v-btn
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="goBack"
          >
            <v-icon small left>mdi-keyboard-backspace</v-icon>
            Back
          </v-btn>
        </div>
      </template>
    </template>

    <template v-slot:body>
      <v-tabs
        v-model="customerTab"
        background-color="transparent"
        color="cyan"
        class="custom-tab-transparent"
      >
        <template v-for="(tab, index) in tabs">
          <v-tab
            class="font-size-16 font-weight-600 px-8"
            :key="index"
            :href="'#' + tab.key"
            :disabled="tab.disabled"
          >
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg
                :src="$assetURL('media/custom-svg/' + tab.icon + '.svg')"
              />
              <!--end::Svg Icon-->
            </span>
            {{ tab.title }}
          </v-tab>
        </template>
      </v-tabs>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll"
        style="max-height: 90vh; position: relative"
      >
        <v-tabs-items v-model="customerTab">
          <v-tab-item v-if="getPermission('customer:view')" :value="'customer'">
            <v-row justify="center">
              <v-expansion-panels v-model="panel" inset>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <template v-slot:default="{ open }">
                      <v-row no-gutters>
                        <v-col cols="6" class="">
                          <template
                            v-if="customerArr.company_type == 'company'"
                          >
                            <h2 class="color-custom-blue font-weight-600">
                              Company Details
                            </h2>
                          </template>
                          <template v-else>
                            <div class="d-flex">
                              <h2 class="color-custom-blue font-weight-600">
                                Contact Details
                              </h2>
                              <v-chip
                                small
                                color="green white--text"
                                label
                                class="ml-2 mt-1"
                                v-if="
                                  customerArr &&
                                  customerArr.default_person &&
                                  customerArr.default_person.default == true
                                "
                              >
                                <span class="font-size-16 font-weight-500">
                                  Primary
                                </span>
                              </v-chip>
                            </div>
                          </template>
                        </v-col>
                        <v-col cols="6">
                          <template v-if="getPermission('customer:update')">
                            <span v-if="open" key="0" class="float-right">
                              <template
                                v-if="customerArr.company_type == 'company'"
                              >
                                <v-skeleton-loader
                                  class="custom-skeleton d-inline-block custom-skeleton-full-width"
                                  v-if="pageLoading"
                                  type="button"
                                  height="28"
                                  width="200"
                                >
                                </v-skeleton-loader>
                                <v-btn
                                  v-else
                                  class="mx-4 custom-bold-button white--text"
                                  color="cyan"
                                  small
                                  v-on:click.native.stop="detailDialog = true"
                                >
                                  <v-icon dark left>mdi-pencil</v-icon>Update
                                  Company Details
                                </v-btn>
                              </template>
                              <template v-else>
                                <v-skeleton-loader
                                  class="custom-skeleton d-inline-block custom-skeleton-full-width"
                                  v-if="pageLoading"
                                  type="button"
                                  height="28"
                                  width="200"
                                >
                                </v-skeleton-loader>
                                <v-btn
                                  v-else
                                  class="mx-4 custom-bold-button white--text"
                                  color="cyan"
                                  small
                                  v-on:click.native.stop="
                                    showUpdateDialog(
                                      customerArr.default_person.id
                                    )
                                  "
                                >
                                  <v-icon dark left>mdi-pencil</v-icon>Update
                                  Contact Details
                                </v-btn>
                              </template>
                            </span>
                          </template>
                        </v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <ContactDetail
                      :pageLoading="pageLoading"
                      :customer="customerArr"
                    ></ContactDetail>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="false">
                  <v-expansion-panel-header>
                    <template v-slot:default="{ open }">
                      <v-row no-gutters>
                        <v-col cols="6" class="margin-auto">
                          <h2
                            class="margin-auto color-custom-blue font-weight-600"
                          >
                            Contact Persons
                          </h2>
                        </v-col>
                        <v-col cols="6">
                          <span v-if="open" key="0">
                            <template
                              v-if="getPermission('contact-person:create')"
                            >
                              <span class="float-right">
                                <v-skeleton-loader
                                  class="custom-skeleton d-inline-block custom-skeleton-full-width"
                                  v-if="pageLoading"
                                  type="button"
                                  height="28"
                                  width="200"
                                >
                                </v-skeleton-loader>
                                <v-btn
                                  v-else
                                  class="mx-2 mr-4 custom-bold-button white--text"
                                  color="cyan"
                                  small
                                  v-on:click.native.stop="
                                    personId = 0;
                                    personDialog = true;
                                  "
                                >
                                  <v-icon dark left>mdi-plus</v-icon>Create
                                  Contact Person
                                </v-btn>
                              </span>
                            </template>
                            <template v-if="personSelectedRows.length > 0">
                              <template
                                v-if="getPermission('contact-person:update')"
                              >
                                <span class="float-right">
                                  <v-skeleton-loader
                                    class="custom-skeleton d-inline-block custom-skeleton-full-width"
                                    v-if="pageLoading"
                                    type="button"
                                    height="28"
                                    width="150"
                                  >
                                  </v-skeleton-loader>
                                  <v-menu
                                    v-else
                                    :close-on-click="true"
                                    bottom
                                    content-class="custom-menu-list"
                                    offset-y
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        class="mx-2 custom-bold-button white--text"
                                        color="cyan"
                                        small
                                      >
                                        <v-icon small dark left
                                          >mdi-chevron-down</v-icon
                                        >Bulk Action
                                      </v-btn>
                                    </template>
                                    <v-list>
                                      <template
                                        v-for="(
                                          item, index
                                        ) in personBulkActions"
                                      >
                                        <template>
                                          <template
                                            v-if="
                                              (item.action == 'default' &&
                                                personSelectedRows.length ==
                                                  1) ||
                                              item.action != 'default'
                                            "
                                          >
                                            <v-list-item
                                              :key="index"
                                              link
                                              v-on:click="
                                                updateCustomerPerson(item)
                                              "
                                            >
                                              <v-list-item-title
                                                class="font-weight-400 my-2 font-size-14"
                                                >{{
                                                  item.title
                                                }}</v-list-item-title
                                              >
                                            </v-list-item>
                                          </template>
                                        </template>
                                      </template>
                                    </v-list>
                                  </v-menu>
                                </span>
                              </template>
                            </template>
                          </span>
                        </v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <ContactPerson
                      :pageLoading="pageLoading"
                      :customer="customerArr"
                      v-on:selectCustomer="showUpdateDialog"
                      v-on:selectedRows="updatePersonSelectedRows"
                    ></ContactPerson>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="false">
                  <v-expansion-panel-header>
                    <template v-slot:default="{ open }">
                      <v-row no-gutters>
                        <v-col cols="4" class="margin-auto">
                          <h2 class="color-custom-blue font-weight-600">
                            Property Address
                          </h2>
                        </v-col>
                        <v-col cols="8">
                          <span v-if="open" key="0">
                            <template v-if="getPermission('property:create')">
                              <span class="float-right">
                                <v-skeleton-loader
                                  class="custom-skeleton d-inline-block custom-skeleton-full-width"
                                  v-if="pageLoading"
                                  type="button"
                                  height="28"
                                  width="200"
                                >
                                </v-skeleton-loader>
                                <v-btn
                                  v-else
                                  class="mx-2 mr-4 custom-bold-button white--text"
                                  color="cyan"
                                  small
                                  v-on:click.native.stop="propertyDialog = true"
                                >
                                  <v-icon dark left>mdi-plus</v-icon>Create
                                  Property Address
                                </v-btn>
                              </span>
                            </template>
                            <template v-if="propertySelectedRows.length > 0">
                              <template v-if="getPermission('property:update')">
                                <span class="float-right">
                                  <v-skeleton-loader
                                    class="custom-skeleton d-inline-block custom-skeleton-full-width"
                                    v-if="pageLoading"
                                    type="button"
                                    height="28"
                                    width="150"
                                  >
                                  </v-skeleton-loader>
                                  <v-menu
                                    v-else
                                    :close-on-click="true"
                                    bottom
                                    content-class="custom-menu-list"
                                    offset-y
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        class="mx-2 custom-bold-button white--text"
                                        color="cyan"
                                        small
                                      >
                                        <v-icon dark left
                                          >mdi-chevron-down</v-icon
                                        >Bulk Action
                                      </v-btn>
                                    </template>
                                    <v-list>
                                      <template
                                        v-for="(
                                          item, index
                                        ) in propertyBulkActions"
                                      >
                                        <v-list-item
                                          :key="index"
                                          link
                                          v-on:click="
                                            updateCustomerProperty(item)
                                          "
                                        >
                                          <v-list-item-title>{{
                                            item.title
                                          }}</v-list-item-title>
                                        </v-list-item>
                                      </template>
                                    </v-list>
                                  </v-menu>
                                </span>
                              </template>
                            </template>
                          </span>
                        </v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <PropertyAddress
                      :pageLoading="pageLoading"
                      :customer="customerArr"
                      v-on:selectProperty="showPropertyDialog"
                      v-on:selectedRows="updatePropertySelectedRows"
                    >
                    </PropertyAddress>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="false">
                  <v-expansion-panel-header>
                    <template v-slot:default="{ open }">
                      <v-row no-gutters>
                        <v-col cols="6" class="margin-auto">
                          <h2
                            class="margin-auto color-custom-blue font-weight-600"
                          >
                            Company Address (Billing Address)
                          </h2>
                        </v-col>
                        <v-col cols="6">
                          <span v-if="open" key="0" class="float-right">
                            <template v-if="getPermission('customer:update')">
                              <v-skeleton-loader
                                class="custom-skeleton d-inline-block custom-skeleton-full-width"
                                v-if="pageLoading"
                                type="button"
                                height="28"
                                width="200"
                              >
                              </v-skeleton-loader>
                              <v-btn
                                v-else
                                class="mx-2 mr-4 custom-bold-button white--text"
                                color="cyan"
                                small
                                v-on:click.native.stop="billingDialog = true"
                              >
                                <v-icon dark left>mdi-pencil</v-icon>Update
                                Company Address
                              </v-btn>
                            </template>
                          </span>
                        </v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <BillingAddress
                      :pageLoading="pageLoading"
                      :customer="customerArr"
                    ></BillingAddress>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="false">
                  <v-expansion-panel-header>
                    <template v-slot:default="{ open }">
                      <v-row no-gutters>
                        <v-col cols="6" class="margin-auto">
                          <h2
                            class="margin-auto color-custom-blue font-weight-600"
                          >
                            Service Forms
                          </h2>
                        </v-col>
                        <v-col cols="6">
                          <span v-if="open" key="0" class="float-right">
                            <template v-if="getPermission('customer:update')">
                              <v-skeleton-loader
                                class="custom-skeleton d-inline-block custom-skeleton-full-width"
                                v-if="pageLoading"
                                type="button"
                                height="28"
                                width="200"
                              >
                              </v-skeleton-loader>
                              <v-btn
                                v-else
                                class="mx-2 mr-4 custom-bold-button white--text"
                                color="cyan"
                                small
                                v-on:click.native.stop="
                                  serviceFormDialog = true
                                "
                              >
                                <v-icon dark left>mdi-pencil</v-icon>Update
                                Service Forms
                              </v-btn>
                            </template>
                          </span>
                        </v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <CustomerServiceForm
                      :customer="customer"
                      :dialog="serviceFormDialog"
                      v-on:close:dialog="serviceFormDialog = false"
                    ></CustomerServiceForm>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="false">
                  <v-expansion-panel-header>
                    <template v-slot:default="{ open }">
                      <v-row no-gutters>
                        <v-col cols="6" class="margin-auto">
                          <h2
                            class="margin-auto color-custom-blue font-weight-600"
                          >
                            Remarks
                          </h2>
                        </v-col>
                        <v-col cols="6">
                          <span v-if="open" key="0" class="float-right">
                            <template v-if="getPermission('customer:update')">
                              <v-skeleton-loader
                                class="custom-skeleton d-inline-block custom-skeleton-full-width"
                                v-if="pageLoading"
                                type="button"
                                height="28"
                                width="200"
                              >
                              </v-skeleton-loader>
                              <v-btn
                                v-else
                                class="mx-2 mr-4 custom-bold-button white--text"
                                color="cyan"
                                small
                                v-on:click.native.stop="
                                  notificationDialog = true
                                "
                              >
                                <v-icon dark left>mdi-pencil</v-icon>Update
                                Remarks
                              </v-btn>
                            </template>
                          </span>
                        </v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <Notification
                      :pageLoading="pageLoading"
                      :customer="customerArr"
                    ></Notification>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-tab-item>
          <v-tab-item :value="'contact-person'">
            <v-expansion-panels
              v-model="panel"
              inset
              style="margin-left: -14px !important"
            >
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <template v-slot:default="{ open }">
                    <v-row no-gutters>
                      <v-col cols="6">
                        <h2
                          class="margin-auto color-custom-blue font-weight-600"
                        >
                          Contact Persons
                        </h2>
                      </v-col>
                      <v-col cols="6">
                        <span v-if="open" key="0">
                          <template
                            v-if="getPermission('contact-person:create')"
                          >
                            <span class="float-right">
                              <v-skeleton-loader
                                class="custom-skeleton d-inline-block custom-skeleton-full-width"
                                v-if="pageLoading"
                                type="button"
                                height="28"
                                width="200"
                              >
                              </v-skeleton-loader>
                              <v-btn
                                v-else
                                class="mx-2 mr-4 custom-bold-button white--text"
                                color="cyan"
                                small
                                v-on:click.native.stop="
                                  personId = 0;
                                  personDialog = true;
                                "
                              >
                                <v-icon dark left>mdi-plus</v-icon>Create
                                Contact Person
                              </v-btn>
                            </span>
                          </template>
                          <template v-if="personSelectedRows.length > 0">
                            <template
                              v-if="getPermission('contact-person:update')"
                            >
                              <span class="float-right">
                                <v-skeleton-loader
                                  class="custom-skeleton d-inline-block custom-skeleton-full-width"
                                  v-if="pageLoading"
                                  type="button"
                                  height="28"
                                  width="150"
                                >
                                </v-skeleton-loader>
                                <v-menu
                                  v-else
                                  :close-on-click="true"
                                  bottom
                                  content-class="custom-menu-list"
                                  offset-y
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-bind="attrs"
                                      v-on="on"
                                      class="mx-2 custom-bold-button white--text"
                                      color="cyan"
                                      small
                                    >
                                      <v-icon small dark left
                                        >mdi-chevron-down</v-icon
                                      >Bulk Action
                                    </v-btn>
                                  </template>
                                  <v-list>
                                    <template
                                      v-for="(item, index) in personBulkActions"
                                    >
                                      <template>
                                        <template
                                          v-if="
                                            (item.action == 'default' &&
                                              personSelectedRows.length == 1) ||
                                            item.action != 'default'
                                          "
                                        >
                                          <v-list-item
                                            :key="index"
                                            link
                                            v-on:click="
                                              updateCustomerPerson(item)
                                            "
                                          >
                                            <v-list-item-title
                                              class="font-weight-400 my-2 font-size-14"
                                              >{{
                                                item.title
                                              }}</v-list-item-title
                                            >
                                          </v-list-item>
                                        </template>
                                      </template>
                                    </template>
                                  </v-list>
                                </v-menu>
                              </span>
                            </template>
                          </template>
                        </span>
                      </v-col>
                    </v-row>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ContactPerson
                    :pageLoading="pageLoading"
                    :customer="customerArr"
                    v-on:selectCustomer="showUpdateDialog"
                    v-on:selectedRows="updatePersonSelectedRows"
                  ></ContactPerson>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <!--   <ContactPerson
              :pageLoading="pageLoading"
              :customer="customerArr"
              v-on:selectCustomer="showUpdateDialog"
              v-on:selectedRows="updatePersonSelectedRows"
            ></ContactPerson> -->
          </v-tab-item>
          <v-tab-item v-if="getPermission('property:view')" value="property">
            <InternalProperty :customer="customerArr.id"></InternalProperty>
          </v-tab-item>
          <v-tab-item v-if="getPermission('job:view')" :value="'job'">
            <Tickets :detail="customerArr" type="customer"></Tickets>
          </v-tab-item>
          <v-tab-item
            v-if="getPermission('quotation:view')"
            :value="'quotation'"
          >
            <Quotations :detail="customerArr" type="customer"></Quotations>
          </v-tab-item>
          <v-tab-item v-if="getPermission('contract:view')" :value="'contract'">
            <Contracts :detail="customerArr" type="customer"></Contracts>
          </v-tab-item>
          <v-tab-item v-if="getPermission('invoice:view')" :value="'invoice'">
            <Invoices :detail="customerArr" type="customer"></Invoices>
          </v-tab-item>
          <v-tab-item v-if="getPermission('payment:view')" :value="'payment'">
            <Payments :detail="customerArr" type="customer"></Payments>
          </v-tab-item>
          <v-tab-item v-if="getPermission('visit:view')" :value="'visit'">
            <VisitListingTemplate
              is-admin
              visit-type="all"
              internal
              is-customer
              :customer-id="customerArr.id"
              :entity-id="customerArr.entity_id"
              :detail-route="'visit.detail'"
            ></VisitListingTemplate>
          </v-tab-item>
          <v-tab-item v-if="getPermission('door:view')" :value="'door'">
            <CustomerDoor customer-detail :customer-id="customerArr?.id ?? 0" />
          </v-tab-item>
          <v-tab-item
            v-if="getPermission('equipment:view')"
            :value="'equipment'"
          >
            <Equipment
              is-customer
              :customer-id="customerArr.id"
              :detail="customerArr"
              type="customer"
            ></Equipment>
          </v-tab-item>
          <v-tab-item v-if="getPermission('service:view')" :value="'service'">
            <Products :detail="customerArr" type="customer"></Products>
          </v-tab-item>
          <v-tab-item v-if="getPermission('warranty:view')" :value="'warranty'">
            <Warranty :detail="customerArr" type="customer"></Warranty>
          </v-tab-item>
          <v-tab-item v-if="getPermission('history:view')" :value="'history'">
            <InternalHistoryDetail
              type="customer"
              :type_id="customerArr.id"
            ></InternalHistoryDetail>
          </v-tab-item>
        </v-tabs-items>
      </perfect-scrollbar>
    </template>
    <template v-slot:footer>
      <Dialog
        v-if="detailDialog"
        :commonDialog="detailDialog"
        :dialogWidth="getContactDialogWith()"
      >
        <template v-slot:title>Update Company Details </template>
        <template v-slot:body>
          <v-form
            :class="{
              'pointer-events-none': !getPermission('customer:update'),
            }"
            ref="customerDetailForm"
            v-model.trim="detailForm"
            lazy-validation
            v-on:submit.stop.prevent="onDetailSubmit('customerDetailForm')"
          >
            <DialogContactDetails
              :customer="customerArr"
              :isDialog="true"
              v-on:saveContactDetail="updateContactDetail"
            >
            </DialogContactDetails>
          </v-form>
        </template>
        <template v-slot:action>
          <template v-if="getPermission('customer:update')">
            <v-btn
              :disabled="!detailForm || formLoading"
              :loading="formLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-on:click="onDetailSubmit('customerDetailForm')"
              >Update
            </v-btn>
          </template>
          <v-btn
            :disabled="formLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="masterDialogClose"
            >Close
          </v-btn>
        </template>
      </Dialog>
      <template v-if="personDialog">
        <DialogContactPersons
          disable-one
          v-model="customerArr.persons"
          :person-dialog="personDialog"
          :do-update="!!+personId"
          :do-save="!!!+personId"
          :person-id="personId"
          :customerId="customerArr.id"
          :customer="customerArr"
          v-on:close="personDialog = false"
          v-on:update:person="rereshDetails"
        ></DialogContactPersons>
      </template>
      <Dialog
        v-if="propertyDialog"
        :commonDialog="propertyDialog"
        :dialogWidth="1024"
      >
        <template v-slot:title>Property Address</template>
        <template v-slot:body>
          <v-form
            :class="{
              'pointer-events-none': !getPermission('property:update'),
            }"
            ref="propertyForm"
            v-model.trim="propertyForm"
            lazy-validation
            v-on:submit.stop.prevent="onDetailSubmit('propertyForm')"
          >
            <DialogPropertyAddress
              :customer="customerArr"
              :isDialog="true"
              :property="propertyId"
              v-on:savePropertyAddress="updatePropertyAddress"
            >
            </DialogPropertyAddress>
          </v-form>
        </template>
        <template v-slot:action>
          <template v-if="getPermission('property:update')">
            <v-btn
              :disabled="!propertyForm || formLoading"
              :loading="formLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-on:click="onDetailSubmit('propertyForm')"
            >
              <span v-if="propertyId > 0">Update</span>
              <span v-else>Create</span>
            </v-btn>
          </template>
          <v-btn
            :disabled="formLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="masterDialogClose"
            >Close
          </v-btn>
        </template>
      </Dialog>
      <Dialog
        v-if="billingDialog"
        :commonDialog="billingDialog"
        :dialogWidth="1024"
      >
        <template v-slot:title>Company Address (Billing Address)</template>
        <template v-slot:body>
          <v-form
            :class="{
              'pointer-events-none': !getPermission('customer:update'),
            }"
            ref="billingForm"
            v-model.trim="billingForm"
            lazy-validation
            v-on:submit.stop.prevent="onDetailSubmit('billingForm')"
          >
            <DialogBillingAddress
              :customer="customerArr"
              :isDialog="true"
              :property="propertyAddress"
              :billing="1"
              v-on:saveBillingAddress="updateBillingAddress"
            >
            </DialogBillingAddress>
          </v-form>
        </template>
        <template v-slot:action>
          <template v-if="getPermission('customer:update')">
            <v-btn
              :disabled="!billingForm || formLoading"
              :loading="formLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-on:click="onDetailSubmit('billingForm')"
              >Update
            </v-btn>
          </template>
          <v-btn
            :disabled="formLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="masterDialogClose"
            >Close
          </v-btn>
        </template>
      </Dialog>
      <Dialog
        v-if="notificationDialog"
        :commonDialog="notificationDialog"
        :dialogWidth="768"
      >
        <template v-slot:title>Notification</template>
        <template v-slot:body>
          <v-form
            :class="{
              'pointer-events-none': !getPermission('customer:update'),
            }"
            ref="notificationForm"
            v-model.trim="notificationForm"
            lazy-validation
            v-on:submit.stop.prevent="onDetailSubmit('notificationForm')"
          >
            <DialogNotification
              :customer="customerArr"
              :isDialog="true"
              v-on:saveNotification="updateNotification"
            >
            </DialogNotification>
          </v-form>
        </template>
        <template v-slot:action>
          <template v-if="getPermission('customer:update')">
            <v-btn
              :disabled="!notificationForm || formLoading"
              :loading="formLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-on:click="onDetailSubmit('notificationForm')"
              >Update
            </v-btn>
          </template>
          <v-btn
            :disabled="formLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="masterDialogClose"
            >Close
          </v-btn>
        </template>
      </Dialog>
      <template>
        <template v-if="transaction_contact_person_dialog">
          <CustomerPersonDialog
            :customerPersonDialog="transaction_contact_person_dialog"
            :customer="transaction_customer"
            v-on:closeDialog="closeDialog"
            v-on:resetAll="resetAll"
            v-on:selectCustomerPerson="selectCustomerPerson"
          >
          </CustomerPersonDialog>
        </template>
        <template v-if="transaction_property_dialog">
          <CustomerPropertyDialog
            :customerPropertyDialog="transaction_property_dialog"
            :customer="transaction_customer"
            v-on:closeDialog="closeDialog"
            v-on:resetAll="resetAll"
            v-on:selectCustomerProperty="selectCustomerProperty"
          >
          </CustomerPropertyDialog>
        </template>
      </template>
    </template>
  </DetailTemplate>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { PUT, PATCH, CLEAR_ERROR } from "@/core/services/store/request.module";
import CustomerDoor from "@/view/pages/customer/Customer-Door.vue";
import ContactDetail from "@/view/pages/customer/detail/overview/Contact-Detail.vue";
import ContactPerson from "@/view/pages/customer/detail/overview/Contact-Person.vue";
import PropertyAddress from "@/view/pages/customer/detail/overview/Property-Address.vue";
import BillingAddress from "@/view/pages/customer/detail/overview/Billing-Address.vue";
import Notification from "@/view/pages/customer/detail/overview/Notification.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import DialogContactDetails from "@/view/pages/customer/create-or-update/Contact-Details.vue";
// import DialogContactPersons from "@/view/pages/customer/create-or-update/Contact-Persons.vue";
import DialogPropertyAddress from "@/view/pages/customer/create-or-update/Property-Address.vue";
import DialogBillingAddress from "@/view/pages/customer/create-or-update/Billing-Address.vue";
import DialogNotification from "@/view/pages/customer/create-or-update/Notification.vue";
import CustomerMixin from "@/core/plugins/customer-mixin";
//import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import PatchCustomerMixin from "@/core/lib/customer/patch.customer.mixin";
import CustomerPersonDialog from "@/view/pages/partials/Select-Customer-Person.vue";
import CustomerPropertyDialog from "@/view/pages/partials/Select-Customer-Property.vue";
import Tickets from "@/view/pages/partials/Detail/Tickets";
import Quotations from "@/view/pages/partials/Detail/Quotation";
import Contracts from "@/view/pages/partials/Detail/Contract";
import Invoices from "@/view/pages/partials/Detail/Invoice";
import Payments from "@/view/pages/partials/Detail/Payments";
import Products from "@/view/pages/partials/Detail/Products";
import Warranty from "@/view/pages/partials/Detail/Warranty";
import Equipment from "@/view/pages/partials/Detail/Equipment";
import CustomerServiceForm from "@/view/pages/partials/Detail/Customer-Service-Form";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import InternalProperty from "@/view/pages/property/Internal-Property";
import DialogContactPersons from "@/view/pages/customer/create-or-update/Create-Contact-Person";
import VisitListingTemplate from "@/view/pages/visit/Visit-Listing-Template";
import { SET_CC_PERSONS } from "@/core/services/store/common.module";

export default {
  mixins: [CommonMixin, ValidationMixin, CustomerMixin, PatchCustomerMixin],
  name: "customer-detail",
  title: "Customer",
  data() {
    return {
      customer: 0,
      serviceFormDialog: false,
      pageLoading: true,
      deleteDialog: false,
      customerArr: {},
      tabs: [
        {
          title: "Overview",
          icon: "customer",
          key: "customer",
          disabled: false,
        },
        {
          title: "Contact Person",
          icon: "contacts",
          key: "contact-person",
          disabled: false,
        },
        {
          title: "Address",
          icon: "property",
          key: "property",
          disabled: false,
        },

        {
          title: "Doors",
          icon: "doors",
          key: "door",
          disabled: false,
        },
        /*{
          title: "Visit",
          icon: "mdi-credit-card",
          key: "visit",
          disabled: false,
        },*/
        {
          title: "Quotations",
          icon: "quotation",
          key: "quotation",
          disabled: false,
        },
        {
          title: "Contracts",
          icon: "contract",
          key: "contract",
          disabled: false,
        },
        /* {
          title: "Invoices",
          icon: "mdi-account-multiple",
          key: "invoice",
          disabled: false,
        }, 
        {
          title: "Payments",
          icon: "mdi-account-multiple",
          key: "payment",
          disabled: false,
        },*/
        {
          title: "Visits",
          icon: "visit",
          key: "visit",
          disabled: false,
        },
        /*{
          title: "Services",
          icon: "mdi-account-multiple",
          key: "service",
          disabled: false,
        },*/
        /*{
          title: "Products",
          icon: "mdi-credit-card",
          key: "product",
          disabled: false
        },
        {
          title: "Warranty",
          icon: "mdi-credit-card",
          key: "warranty",
          disabled: false
        },*/
        {
          title: "History",
          icon: "history",
          key: "history",
          disabled: false,
        },
      ],
      createTransactions: [
        // {
        //   title: "Visit",
        //   action: "visit",
        // },
        /*{
          title: "Quotation",
          action: "quotation",
        },*/
        /*{
          title: "Invoice",
          action: "invoice",
        },*/
        {
          title: "Site Information",
          action: "property",
        },
      ],
      customerMoreAction: [
        {
          title: "Mark as Active",
          icon: "mdi-check-all",
          action: "active",
        },
        {
          title: "Mark as In-Active",
          icon: "mdi-check-all",
          action: "inactive",
        },
      ],
      //customerTab: null,
      panel: 0,
      personSelectedRows: [],
      propertySelectedRows: [],
      propertyAddress: {},
      billingAddress: {},
      contactDetail: {},
      contactPerson: [],
      notification: {},
      detailForm: true,
      detailDialog: false,
      formLoading: false,
      personId: 0,
      personForm: true,
      personDialog: false,
      propertyId: 0,
      propertyForm: true,
      propertyDialog: false,
      billingForm: true,
      billingDialog: false,
      notificationForm: true,
      notificationDialog: false,
    };
  },
  components: {
    VisitListingTemplate,
    InternalProperty,
    CustomerDoor,
    ContactDetail,
    ContactPerson,
    PropertyAddress,
    BillingAddress,
    Notification,
    Dialog,
    DialogContactDetails,
    DialogContactPersons,
    DialogPropertyAddress,
    DialogBillingAddress,
    DialogNotification,
    DetailTemplate,
    CustomerPersonDialog,
    CustomerPropertyDialog,
    Tickets,
    Quotations,
    Contracts,
    Invoices,
    Payments,
    Equipment,
    Products,
    Warranty,
    CustomerServiceForm,
    InternalHistoryDetail,
  },
  watch: {
    panel() {
      this.masterDialogClose();
    },
  },
  methods: {
    getContactDialogWith() {
      if (window.innerWidth > 1240) {
        return 1320;
      }
      return window.innerWidth - 300;
    },
    transferOwnership() {
      this.$router.push({
        name: "admin.customer.create",
        query: {
          "transfer-ownership": this.customerArr?.id ?? 0,
          t: Number(new Date()),
        },
      });
    },
    rereshDetails() {
      let _this = this;
      _this
        .getCustomer()
        .then((response) => {
          _this.customerArr = response;
          _this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Customer", route: "customer" },
            { title: "Detail" },
            { title: response.barcode },
          ]);

          if (_this.customerArr && _this.customerArr.persons) {
            this.$store.dispatch(SET_CC_PERSONS, _this.customerArr.persons);
          }
        })
        .catch((error) => {
          _this.logError(error);
          _this.$router.go(-1);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateMoreAction(action) {
      const _this = this;
      let formData = {};
      switch (action) {
        case "active":
          formData.status = 1;
          break;
        case "inactive":
          formData.status = 0;
          break;
      }
      _this
        .patchCustomer(_this.customer, formData)
        .then(() => {
          _this.getCustomer().then((response) => {
            _this.customerArr = response;
          });
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    activeInactive(data) {
      const _this = this;
      if (data.activated == false) {
        data.activated = 0;
      } else {
        data.activated = 1;
      }
      _this.$store
        .dispatch(PATCH, {
          url: `single-customer/${data.activated}/${data.id}`,
          data: {
            id: data.id,
            status: data.activated,
          },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.getCustomer();
        });
    },
    updatePropertyAddress(param) {
      this.propertyAddress = param;
    },
    updateBillingAddress(param) {
      this.billingAddress = param;
    },
    updateContactDetail(param) {
      this.contactDetail = param;
    },
    updateContactPerson(param) {
      this.contactPerson = param;
    },
    updateNotification(param) {
      this.notification = param;
    },
    updatePersonSelectedRows(param) {
      this.bulkRows = { persons: param };
      this.personSelectedRows = param;
    },
    updatePropertySelectedRows(param) {
      this.bulkRows = { property: param };
      this.propertySelectedRows = param;
    },
    updateCustomerPerson({ action }) {
      const _this = this;
      let formData = {};
      switch (action) {
        case "active":
          formData.status = 1;
          break;
        case "inactive":
          formData.status = 0;
          break;
        case "default":
          formData.default = 1;
          break;
      }
      _this
        .patchCustomerContactPerson(
          _this.customer,
          _this.personSelectedRows,
          formData
        )
        .then(() => {
          _this.getCustomer().then((response) => {
            _this.customerArr = response;
          });
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    updateCustomerProperty({ action }) {
      const _this = this;
      let formData = {};
      switch (action) {
        case "active":
          formData.status = 1;
          break;
        case "inactive":
          formData.status = 0;
          break;
      }
      _this
        .patchCustomerProperty(
          _this.customer,
          _this.propertySelectedRows,
          formData
        )
        .then(() => {
          _this.getCustomer().then((response) => {
            _this.customerArr = response;
          });
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    async onDetailSubmit(formRef) {
      const _this = this;

      try {
        if (!_this.$refs[formRef].validate()) {
          return false;
        }

        _this.pageLoading = true;
        _this.formLoading = true;
        _this.$store.dispatch(CLEAR_ERROR, {});

        let formData = new Object();
        let personFormData = new Object();
        /*  let detailFormData = new Object(); */
        let addressFormData = new Array();

        if (_this.lodash.isEmpty(_this.contactDetail) === false) {
          for (let key in _this.contactDetail) {
            formData[key] = _this.contactDetail[key];
          }

          if (_this.lodash.isEmpty(formData.profile_logo) === false) {
            formData.profile_logo = formData.profile_logo.id;
          }
        }

        /* if (_this.lodash.isEmpty(_this.contactDetail) === false) {
          if (
            Object.prototype.hasOwnProperty.call(
              _this.contactDetail,
              "details"
            ) &&
            _this.lodash.isEmpty(_this.contactDetail.details) === false
          ) {
            detailFormData = _this.contactDetail.details;
          }
        }
 */
        if (_this.lodash.isEmpty(_this.notification) === false) {
          if (
            Object.prototype.hasOwnProperty.call(
              _this.notification,
              "client_reminders"
            )
          ) {
            formData.client_reminders = _this.notification.client_reminders;
          }
          if (
            Object.prototype.hasOwnProperty.call(
              _this.notification,
              "client_follow_up_emails"
            )
          ) {
            formData.client_follow_up_emails =
              _this.notification.client_follow_up_emails;
          }
          if (
            Object.prototype.hasOwnProperty.call(
              _this.notification,
              "print_email_on_work_order"
            )
          ) {
            formData.print_email_on_work_order =
              _this.notification.print_email_on_work_order;
          }
          if (
            Object.prototype.hasOwnProperty.call(
              _this.notification,
              "customer_remark"
            )
          ) {
            formData.customer_remark = _this.notification.customer_remark;
          }
        }

        if (_this.lodash.isEmpty(_this.contactPerson) === false) {
          personFormData = _this.contactPerson;
        }

        if (_this.lodash.isEmpty(_this.propertyAddress) === false) {
          addressFormData.push(_this.propertyAddress);
        }

        if (_this.lodash.isEmpty(_this.billingAddress) === false) {
          addressFormData.push(_this.billingAddress);
        }

        //formData = _this.lodash.pickBy(formData, _this.lodash.identity);
        addressFormData = _this.lodash.pickBy(
          addressFormData,
          _this.lodash.identity
        );

        if (_this.lodash.isEmpty(formData) === false) {
          await _this.$store.dispatch(PATCH, {
            url: "customer/" + _this.customer,
            data: formData,
          });
        }

        if (_this.lodash.isEmpty(personFormData) === false) {
          await _this.$store.dispatch(PUT, {
            url: "customer/" + _this.customer + "/contact-person",
            data: { persons: personFormData },
          });
        }

        /*  if (_this.lodash.isEmpty(detailFormData) === false) {
          await _this.$store.dispatch(PUT, {
            url: "customer/" + _this.customer + "/detail",
            data: { details: detailFormData },
          });
        } */

        if (_this.lodash.isEmpty(addressFormData) === false) {
          await _this.$store.dispatch(PUT, {
            url: "address/" + _this.customer,
            data: { address: addressFormData },
          });
        }

        _this
          .getCustomer()
          .then((response) => {
            _this.customerArr = response;
            _this.masterDialogClose();
          })
          .finally(() => {
            _this.formLoading = false;
            _this.pageLoading = false;
          });
      } catch (error) {
        _this.formLoading = false;
        _this.pageLoading = false;
        //ErrorEventBus.$emit("update:error", InitializeError(error));
      }
    },
    showUpdateDialog(param) {
      this.personId = param;
      this.$nextTick(() => {
        this.personDialog = true;
      });
    },

    showPropertyDialog(param) {
      this.propertyId = param;
      this.$nextTick(() => {
        this.propertyDialog = true;
      });
    },
    masterDialogClose() {
      this.$root.$emit("clearSelection", true);
      this.personSelectedRows = [];
      this.propertySelectedRows = [];
      this.propertyAddress = {};
      this.billingAddress = {};
      this.contactDetail = {};
      this.contactPerson = [];
      this.notification = {};
      this.detailForm = true;
      this.detailDialog = false;
      this.formLoading = false;
      this.personId = 0;
      this.personForm = true;
      this.personDialog = false;
      this.propertyId = 0;
      this.propertyForm = true;
      this.propertyDialog = false;
      this.billingForm = true;
      this.billingDialog = false;
      this.notificationForm = true;
      this.notificationDialog = false;
      this.deleteDialog = false;
    },
  },
  mounted() {
    const _this = this;
    _this.checkTransactionPermission();
    _this.checkTabPermission();
    _this
      .getCustomer()
      .then((response) => {
        _this.customerArr = response;
        _this.$store.dispatch(SET_BREADCRUMB, [
          { title: "Customer", route: "customer" },
          { title: "Detail" },
          { title: response.barcode },
        ]);

        if (_this.customerArr && _this.customerArr.persons) {
          this.$store.dispatch(SET_CC_PERSONS, _this.customerArr.persons);
        }
      })
      .catch((error) => {
        _this.logError(error);
        _this.$router.go(-1);
      })
      .finally(() => {
        _this.pageLoading = false;
      });
  },
  created() {
    const _this = this;
    _this.customer = _this.$route.params.id;
    if (!_this.customer || _this.customer <= 0) {
      _this.$router.go(-1);
    }

    if (_this.$route.query && _this.$route.query.tab) {
      _this.customerTab = _this.$route.query.tab;
    }
  },
  computed: {
    getPageTitle() {
      let result = [];
      if (this.lodash.isEmpty(this.customerArr) === false) {
        if (this.customerArr.display_name) {
          result.push(this.customerArr.display_name);
        }
        /* if (this.customerArr.barcode) {
          result.push(this.customerArr.barcode);
        } */
      }
      return result.join(" • ");
    },
    customerTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        if (val != this.customerTab) {
          this.$router.push({ query });
        }
      },
      get() {
        return this.$route.query.tab || "customer";
      },
    },
  },
};
</script>
